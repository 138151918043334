<template>
  <section
    v-show="user.information"
    class="text-left composeDialog dialogWindow"
    v-bind:class="windowClass"
  >
    <div v-if="!loaExternalEnabled">
      <div class="info-header mt-2">{{ $t("LOA.TITLE") }}</div>

      <p class="mt-2 mb-4">
        {{ $t("LOA.LOA_EXTERNAL_DISABLED") }}{{ loa_level }}
      </p>

      <b-button @click="closeBtn">{{ $t("CANCEL") }}</b-button>
    </div>

    <div v-if="loaExternalEnabled">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="!loading">
          <p>
            {{ $t("COMPOSE.NOT_REGISTERED") }}
          </p>
          <h6>{{ $t("COMPOSE.NOT_REGISTERED_INFORMATION") }}</h6>

          <div class="mt-2 mb-4">
            <NotRegistered
              v-for="(item, index) in external_participants"
              :key="index"
              @setValidated="setValidated"
              :methods="methods"
              :loa_level="loa_level"
              :item="item"
              :index="index"
            ></NotRegistered>
          </div>

          <div>
            <h6 class="mt-4">{{ $t("COMPOSE.EXTERNAL_TEXT") }}</h6>
            <small>{{ $t("COMPOSE.EXTERNAL_TEXT_INFORMATION") }}</small>
            <div class="mt-2 mb-2">                
              <b-form-textarea
                class="textarea"
                id="textarea"
                v-model="external_text"
                :state="external_text.length <= 1500"
                :disabled="settings.email_body != ''"
                :placeholder="$t('MESSAGES.EXTERNAL_TEXT_PLACEHOLDER')"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
              <div class="clearfix">
                <div class="float-left">
                  <div class="error-text" v-if="external_text.length > 1500">
                    {{ $t('ERROR.TOO_MANY_CHARACTERS')}}
                  </div>           
                  <b-button
                    v-if="settings.email_body != '' && settings.unlock_email_body"
                    @click="unlockEmailBody" 
                    size="sm"
                    class="no-border mt-2 mb-2"><i class="fal fa-unlock"></i> {{ $t("BTN_UNLOCK_EMAIL_BODY") }}</b-button>                
      
                </div>
                <div class="float-right">
                  <div class="text-right">
                    <small class="pull-right">{{external_text.length}}/1500</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="mt-2 mb-2">
            <b-button-group>
              <button
                :disabled="disableSend"
                v-on:click="sendBtn"
                class="btn btn-fill btn-primary"
              >
                {{ $t("COMPOSE.BUTTON_DIALOG") }}
              </button>
              <b-button @click="closeBtn">{{ $t("CANCEL") }}</b-button>
            </b-button-group>
          </div>
        </div>
        <div v-if="loading" class="mt-2">
          <InformationMessage type="information">
            <i class="fal fa-info-circle"></i> {{ actionMessage }}
          </InformationMessage>
        </div>
      </b-overlay>
    </div>
  </section>
</template>
<script>
import URLSearchParams from "@ungap/url-search-params";
export default {
  components: {
    NotRegistered: () => import("@/components/Compose/NotRegistered"),
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
  },
  data() {
    return {
      settings: null,
      validated: [],
      methods: {},
      loading: true,
      actionMessage: "Loading",
      userlist: {},
      registered: [],
      loa_level: 1,
      notregistered: [],
      external_text: "",
      external_participants: [],
      locale: this.$i18n.locale,
    };
  },
  methods: {
    unlockSubject(){
      if(this.reply_subject == "")
      {
        this.subject = this.settings.subject;
      }else{
        this.subject = this.reply_subject;
      }
      this.settings.subject = "";
      this.settings.unlock_subject = 0;
    },
    unlockEmailBody(){
      this.settings.email_body = "";
      this.settings.unlock_email_body = 0;
    },
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .get(
          this.user.hostname +
            "/sefos-message/settings/" +
            this.user.information.organisation.organisation_uuid
        )
        .then(function (result) {
          self.settings = result.data;
          self.init();
          self.loading = false;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH"));
        });
    },
    async init() {
      if(this.settings.email_body != '')
      {
        this.external_text = this.settings.email_body;
      }
      await this.getMethods();
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.loading = true;
      this.loa_level = params.get("loa_level");
      this.userlist = JSON.parse(params.get("userlist"));
      this.registered = this.userlist.registered;
      this.notregistered = this.userlist.notregistered;
      let default_method = "";
      for (let ax = 0; ax < this.methods.length; ax++) {
        let method = this.methods[ax];
        if (default_method == "") {
          if (this.user.loa_level_enabled.loa3_enabled == 3) {
            if (method.loa_level >= this.loa_level) {
              default_method = method.name;
            }
          }
          if (this.user.loa_level_enabled.loa2_enabled == 2) {
            if (method.loa_level >= this.loa_level) {
              default_method = method.name;
            }
          }
          if (this.user.loa_level_enabled.loa1_enabled == 1) {
            if (method.loa_level >= this.loa_level) {
              default_method = method.name;
            }
          }
        }
      }
      for (let ix = 0; ix < this.notregistered.length; ix++) {
        this.external_participants.push({
          email: this.notregistered[ix],
          authentication_method: default_method,
          authentication_identifier: "",
          language: this.locale,
        });
        if (default_method == "link") {
          this.validated.push({
            email: this.notregistered[ix],
            valid: 1,
          });
        } else {
          this.validated.push({
            email: this.notregistered[ix],
            valid: 0,
          });
        }
      }
      this.loading = false;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function (result) {
          self.methods = result.data;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t(error.response));
        });
    },
    setValidated(payload) {
      if (payload.value) {
        this.validated[payload.index].valid = 1;
      } else {
        this.validated[payload.index].valid = 0;
      }
    },
    closeBtn() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "CLOSE",
        })
      );
    },
    sendBtn() {
      if (this.external_text == "") {
        this.external_text = this.$t("MESSAGES.EXTERNAL_TEXT_PLACEHOLDER");
      }
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "SEND",
          external_participants: JSON.stringify(this.filteredParticipants),
          removed_participants: JSON.stringify(
            this.filteredRemovedParticipants
          ),
          external_text: this.external_text,
        })
      );
    },
  },
  computed: {
    loaExternalEnabled: function () {
      if (this.loa_level == 3) {
        return this.user.external_loa_levels.loa3;
      }
      if (this.loa_level == 2) {
        return (
          this.user.external_loa_levels.loa3 ||
          this.user.external_loa_levels.loa2
        );
      }
      if (this.loa_level == 1) {
        return (
          this.user.external_loa_levels.loa3 ||
          this.user.external_loa_levels.loa2 ||
          this.user.external_loa_levels.loa1
        );
      }
      return false;
    },
    disableSend() {
      if (this.filteredParticipants == 0 && this.registered.length == 0) {
        return true;
      } else {
        if(this.external_participants.length > 0)
        {
          if(this.external_text.length > 1500)
          {
            return true;
          }
        }
        return (
          this.external_participants.length != this.validatedParticipants.length
        );
      }
    },
    filteredParticipants() {
      return this.external_participants.filter(function (item) {
        return item.authentication_method !== "donotsend";
      });
    },
    filteredRemovedParticipants() {
      return this.external_participants.filter(function (item) {
        return item.authentication_method == "donotsend";
      });
    },
    validatedParticipants() {
      return this.validated.filter(function (item) {
        return item.valid == 1;
      });
    },
    windowClass() {
      if (this.$Office.context.diagnostics.platform == undefined) {
        return "";
      } else {
        if (this.$Office.context.diagnostics.platform == "OfficeOnline") {
          return "pr-2";
        } else {
          return "pl-md-4 pr-md-4";
        }
      }
    },
  },
  mounted() {
    console.log("ComposeDialog mounted");
    this.getSettings();
  },
};
</script>
<style></style>
